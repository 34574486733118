export const ERROR_CODES = {
  NOT_FOUND: '404',
  SERVER_ERROR: '500',
  LOGIN_FAILED: '403',
};

export const ERROR_MESSAGES = {
  NOT_FOUND: 'Page Not Found',
  SERVER_ERROR: 'Internal Server error',
  LOGIN_FAILED: 'Login failed',
};

export const ERROR_TAB_NAMES = {
  SERVER_ERROR: 'Internal server error',
  LOGIN_FAILED: 'Login failed',
};

export const ERROR_CASES = {
  LOGIN_FAILED: '403_login',
  SERVER_ERROR: '500',
};
