import React from 'react';
import { useGlobalContext } from '../../components/GlobalContextProvider/GlobalContextProvider';
import Header from '../../components/Header/Header';
import UniversalLink from '../../components/UniversalLink/UniversalLink';
import { ERROR_MESSAGES, ERROR_CODES } from '../../consts/errorCodes';
import {
  GradientContainer,
  Label404,
  Description,
  HomeButton,
} from '../../shared-styled/blocks/errorPagesBlocks';
import {
  BasePageContainer,
  ChildrenWrapper,
} from '../BasePage/BasePage.styled';
import NavigationPage from '../NavigationPage/NavigationPage';

const NotFoundPage = () => {
  const { isNavActive } = useGlobalContext();

  return (
    <BasePageContainer>
      <Header />
      {isNavActive ? (
        <NavigationPage isNotFoundPage={true} />
      ) : (
        <ChildrenWrapper>
          <GradientContainer>
            <Label404>{ERROR_CODES.NOT_FOUND}</Label404>
            <Description>{ERROR_MESSAGES.NOT_FOUND}</Description>
            <UniversalLink href='/'>
              <HomeButton>Go Home</HomeButton>
            </UniversalLink>
          </GradientContainer>
        </ChildrenWrapper>
      )}
    </BasePageContainer>
  );
};

NotFoundPage.propTypes = {};

NotFoundPage.defaultProps = {};

export default NotFoundPage;
