import styled from '@emotion/styled';
import { css } from 'theme-ui';
import pxToRem from '../../utils/pixelsToRem';
import { Gradient } from '../layout/Gradient';
import { TextButton } from './buttons';

export const GradientContainer = styled(Gradient)(
  css({
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    fontFamily: '"Urbanist"',
    fontWeight: '200',
    letterSpacing: '0.03em',
    textAlign: 'center',
  }),
);

export const Label404 = styled.p(
  css({
    mb: '12px',
    fontSize: pxToRem(96),
  }),
);

export const Description = styled.p(
  css({
    mb: '90px',
    fontSize: pxToRem(32),
  }),
);

export const Message = styled.p(
  css({
    mb: '20px',
    fontSize: pxToRem(32),
    fontWeight: '500',
  }),
);

export const SmallMessage = styled.p(
  css({
    mb: '30px',
    fontSize: pxToRem(28),
  }),
);

export const HomeButton = styled(TextButton)(
  css({
    px: '28px',
    py: '14px',
  }),
);
