import styled from '@emotion/styled';
import { css } from 'theme-ui';

export const TextButton = styled.button(
  css({
    background: 'white',
    fontFamily: 'Roboto',
    fontWeight: '400',
    lineHeight: '20px',
    cursor: 'pointer',
    color: 'text',

    '&:hover': {
      background: 'focus',
    },
  }),
);

export const GreenButton = styled.button(
  css({
    background: 'darkGreen',
    fontFamily: 'Roboto',
    color: 'white',
    cursor: 'pointer',
  }),
);
