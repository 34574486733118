import dynamic from 'next/dynamic';

export default {
  BasePage: dynamic(() => import('./BasePage')),
  HomePage: dynamic(() => import('./HomePage')),
  NotFoundPage: dynamic(() => import('./NotFoundPage')),
  PasswordProtectedPage: dynamic(() => import('./PasswordProtectedPage')),
  PureHtmlPage: dynamic(() => import('./PureHtmlPage')),
  StaticPage: dynamic(() => import('./StaticPage')),
  NewsPage: dynamic(() => import('./NewsPage')),
  NewsListPage: dynamic(() => import('./NewsListPage')),
  ReportPage: dynamic(() => import('./ReportPage')),
  ReportListPage: dynamic(() => import('./ReportListPage')),
  ArmedForcesPage: dynamic(() => import('./ArmedForcesPage')),
  GeographicRegionsPage: dynamic(() => import('./GeographicRegionsPage')),
  ArmedForcesListPage: dynamic(() => import('./ArmedForcesListPage')),
  MilitaryOrganizationPage: dynamic(() => import('./MilitaryOrganizationPage')),
  RegionPage: dynamic(() => import('./RegionPage')),
  MilitaryOrganizationListPage: dynamic(() =>
    import('./MilitaryOrganizationListPage'),
  ),
  ContactUs: dynamic(() => import('./ContactUs')),
  SubscribetoMilitaryPeriscope: dynamic(() =>
    import('./SubscribetoMilitaryPeriscope'),
  ),
  WeaponsPage: dynamic(() => import('./WeaponsPage')),
  WeaponDetailPage: dynamic(() => import('./WeaponDetailPage')),
  WeaponsCategoryPage: dynamic(() => import('./WeaponsCategoryPage')),
  WhatsNewPage: dynamic(() => import('./WhatsNewPage')),
  ForbiddenPage: dynamic(() => import('./ForbiddenPage')),
  Page304: dynamic(() => import('./Page304')),
  Page503: dynamic(() => import('./Page503')),
};
