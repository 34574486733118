import styled from '@emotion/styled';
import { Flex, css } from 'theme-ui';

export const Gradient = styled(Flex)(
  css({
    background: 'linear-gradient(90deg, #191e1d 13.83%, #123239 53.2%)',
    color: 'white',
  }),
);

export const DarkGradient = styled(Flex)(
  css({
    background: 'linear-gradient(90deg, #191D1C 0%, #1A3138 100%)',
    color: 'white',
  }),
);
