import Head from 'next/head';
import React from 'react';
import { useGlobalContext } from '../../components/GlobalContextProvider/GlobalContextProvider';
import Header from '../../components/Header/Header';
import {
  ERROR_MESSAGES,
  ERROR_CODES,
  ERROR_TAB_NAMES,
} from '../../consts/errorCodes';
import {
  GradientContainer,
  Label404,
  Description,
  HomeButton,
} from '../../shared-styled/blocks/errorPagesBlocks';
import {
  BasePageContainer,
  ChildrenWrapper,
} from '../BasePage/BasePage.styled';
import NavigationPage from '../NavigationPage/NavigationPage';

const LoginFailedPage = () => {
  const { isNavActive } = useGlobalContext();
  return (
    <>
      <Head>
        <title>{ERROR_TAB_NAMES.LOGIN_FAILED}</title>
      </Head>
      <BasePageContainer>
        <Header />
        {isNavActive ? (
          <NavigationPage isNotFoundPage={true} />
        ) : (
          <ChildrenWrapper>
            <GradientContainer>
              <Label404>{ERROR_CODES.LOGIN_FAILED}</Label404>
              <Description>{ERROR_MESSAGES.LOGIN_FAILED}</Description>
              <form>
                <input type='hidden' name='next' value='' />
                <HomeButton
                  formMethod='get'
                  formAction='/accounts/auth0/login/'
                  type='submit'
                >
                  Try Again
                </HomeButton>
              </form>
            </GradientContainer>
          </ChildrenWrapper>
        )}
      </BasePageContainer>
    </>
  );
};

LoginFailedPage.propTypes = {};

LoginFailedPage.defaultProps = {};

export default LoginFailedPage;
