import Head from 'next/head';
import React from 'react';
import { useGlobalContext } from '../../components/GlobalContextProvider/GlobalContextProvider';
import Header from '../../components/Header/Header';
import UniversalLink from '../../components/UniversalLink/UniversalLink';
import {
  ERROR_MESSAGES,
  ERROR_CODES,
  ERROR_TAB_NAMES,
} from '../../consts/errorCodes';
import {
  GradientContainer,
  Label404,
  Description,
  HomeButton,
} from '../../shared-styled/blocks/errorPagesBlocks';
import {
  BasePageContainer,
  ChildrenWrapper,
} from '../BasePage/BasePage.styled';
import NavigationPage from '../NavigationPage/NavigationPage';

const InternalServerErrorPage = () => {
  const { isNavActive } = useGlobalContext();
  return (
    <>
      <Head>
        <title>{ERROR_TAB_NAMES.SERVER_ERROR}</title>
      </Head>
      <BasePageContainer>
        <Header />
        {isNavActive ? (
          <NavigationPage isNotFoundPage={true} />
        ) : (
          <ChildrenWrapper>
            <GradientContainer>
              <Label404>{ERROR_CODES.SERVER_ERROR}</Label404>
              <Description>{ERROR_MESSAGES.SERVER_ERROR}</Description>
              <UniversalLink href='/'>
                <HomeButton>Go Home</HomeButton>
              </UniversalLink>
            </GradientContainer>
          </ChildrenWrapper>
        )}
      </BasePageContainer>
    </>
  );
};

InternalServerErrorPage.propTypes = {};

InternalServerErrorPage.defaultProps = {};

export default InternalServerErrorPage;
